const palette = {
  core: {
    darkGrey: "#323036",
    darkPurple: "#AB75FA",
    lightPurple: "#D8C5F8",
    lightGrey: "#F2F1F7",
  },
  extended: {
    darkPurple1: "#B98EF9",
    darkPurple2: "#C7A7F9",
    darkPurple3: "#D6BFF8",
    pink: "#FBB0DD",
    pink1: "#F9C0E4",
    pink2: "#F6D1EA",
    pink3: "#F4E1F1",
    orange: "#F9BF90",
    orange1: "#F6D3B9",
    orange2: "#F5DDCE",
    orange3: "#F3E7E2",
    mint: "#7CD09D",
    mint1: "#AFEECB",
    mint2: "#C6EFDA",
    mint3: "#DCF0E8",
    coral0: "#F46869",
    coral: "#FB9899",
    coral1: "#F9AEB1",
    coral2: "#F6C5C8",
    coral3: "#F4DBE0",
  },
  darkBackground: {
    darkPurple: "#B482FD",
    pink: "#FFA6DB",
    orange: "#FFCA9E",
    mint: "#99FAC0",
    coral: "#FA8E8E",
  },
  extendedUI: {
    lightPurple: "#EFE5FF",
    offsetWhite: "#F9F9FB",
    darkGrey1: "#4C4952",
    darkGrey2: "#3A373E",
    darkGrey3: "#2A282D",
    lightGrey: "#EEECF3", // for inset
    lightGrey1: "#D1D1D3", // for inactive icons
    grey1: "#A9A9AC", // for body copy on dark bk
    grey2: "#828085", // for lighter body copy on light bk
    grey3: "#58575D", // for body copy on light bk
    grey4: "#BDBDC0", // for borders
    mintInset: "#78B590",
    darkPurpleInset: "#9162D7",
    orangeInset: "#DEA77A",
  },
}

export default {
  palette,
  light: {
    primaryText: palette.core.darkGrey,
    softText: palette.extendedUI.grey2,
    secondaryText: palette.extendedUI.grey3,
    primaryBackground: palette.core.lightGrey,
    highlightBackground: palette.extendedUI.offsetWhite,
    accentBackground: palette.extendedUI.lightPurple,
    accentColor: palette.core.darkPurple,
    insetBackground: palette.extendedUI.lightGrey,
    floatingContainerBackground: palette.extendedUI.offsetWhite,
    divider: palette.extendedUI.grey4,

    // Code highlighting
    codeBackground: palette.extendedUI.offsetWhite,
    codeDiminished: palette.extendedUI.grey2,
    codeAccent1: palette.core.darkPurple,
    codeAccent2: palette.core.darkPurple,
    codeAccent3: palette.extended.coral0,
    codeAccent4: palette.extendedUI.mintInset,
    codeAccent5: palette.extendedUI.orangeInset,
  },
  dark: {
    primaryText: palette.extendedUI.offsetWhite,
    softText: palette.core.lightGrey,
    secondaryText: palette.extendedUI.grey1,
    primaryBackground: palette.core.darkGrey,
    highlightBackground: palette.extendedUI.darkGrey1,
    accentBackground: palette.extendedUI.darkGrey1,
    accentColor: palette.darkBackground.darkPurple,
    insetBackground: palette.extendedUI.darkGrey3,
    floatingContainerBackground: palette.extendedUI.darkGrey2,
    divider: palette.extendedUI.grey3,

    // Code highlighting
    codeBackground: palette.extendedUI.darkGrey3,
    codeDiminished: palette.extendedUI.grey2,
    codeAccent1: palette.core.darkPurple,
    codeAccent2: palette.core.lightPurple,
    codeAccent3: palette.extended.coral,
    codeAccent4: palette.extended.mint,
    codeAccent5: palette.extended.orange,
  },
}
