import normalize from "emotion-normalize"
import repackage from "lib/repackage"
import merge from "lodash-es/merge"
import upperFirst from "lodash-es/upperFirst"
import colors from "styles/colors"
import fontDeclarations from "styles/fonts"

export const breakpoints = {
  tablet: 1100,
  mobile: 850,
  smallMobile: 648,
  mobilePortrait: 530,
}

const mediaQueries = {
  desktop: `@media (min-width: ${breakpoints.tablet + 1})`,
  tablet: `@media (max-width: ${breakpoints.tablet}px)`,
  mobile: `@media (max-width: ${breakpoints.mobile}px)`,
  smallMobile: `@media (max-width: ${breakpoints.smallMobile}px)`,
  mobilePortrait: `@media (max-width: ${breakpoints.mobilePortrait}px)`,
  reducedMotion: "@media (prefers-reduced-motion)",
  landscape: "@media (orientation: landscape)",
  portrait: "@media (orientation: portrait)",
  retina: "@media (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)",
  max: (maxWidth) => `@media (max-width: ${maxWidth}px)`,
  min: (minWidth) => `@media (min-width: ${minWidth}px)`,
  range: (min, max) =>
    mediaQueries.merge(mediaQueries.min(min), mediaQueries.max(max)),
  merge: (...mediaQueries) =>
    "@media " +
    mediaQueries
      .map((query) => (query.startsWith("@media") ? query.slice(7) : query))
      .join(" and "),
}

const fonts = {
  display:
    'SMAlgebraDisplay, "Algebra Display", "Kosugi", SMForma, "Forma DJR Text", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  copy:
    'SMForma, "Forma DJR Text", "Kosugi Maru", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
}

// Text Styles
const { vars: customProperties, styles: textStyles } = Object.entries({
  h1: {
    fontFamily: fonts.display,
    // fontSize: [56, 42, 28],
    fontSize: [56, 40, 22],
    fontWeight: 500,
    lineHeight: 1.2,
    fontFeatureSettings: "'ss03' on",
  },
  h2: {
    fontFamily: fonts.display,
    // fontSize: [44, 33, 22],
    fontSize: [44, 30, 16],
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: "0.01em",
  },
  h3: {
    fontFamily: fonts.display,
    // fontSize: [32, 25, 18],
    fontSize: [32, 22, 12],
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "0.01em",
  },
  h4: {
    fontFamily: fonts.display,
    // fontSize: [24, 20, 16],
    fontSize: [24, 17, 12],
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: "0.01em",
  },
  p1: {
    fontFamily: fonts.copy,
    fontSize: [28, 23, 18],
    lineHeight: ["1.5", null, "1.3"],
    letterSpacing: "0.02em",
  },
  p2: {
    fontFamily: fonts.copy,
    fontSize: [22, 18, 14],
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
  p3: {
    fontFamily: fonts.copy,
    fontSize: [18, 16, 14],
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
}).reduce(
  (output, [selector, properties]) => {
    output.styles[selector] = Object.entries(properties).reduce(
      (preparedProperties, [property, value]) => {
        if (Array.isArray(value)) {
          const varName = `--${selector}${upperFirst(property)}`
          const [desktopValue, tabletValue, mobileValue] = value.map((v) =>
            typeof v === "number" ? `${v}px` : v
          )

          output.vars[varName] = desktopValue
          output.vars[mediaQueries.tablet][varName] = tabletValue
          output.vars[mediaQueries.mobile][varName] = mobileValue
          preparedProperties[property] = `var(${varName}, ${desktopValue})`
        } else {
          preparedProperties[property] = value
        }

        return preparedProperties
      },
      {}
    )

    return output
  },
  { vars: { [mediaQueries.tablet]: {}, [mediaQueries.mobile]: {} }, styles: {} }
)

// Shadows
const { definitions: shadowDefinitions, ...shadows } = repackage(
  {
    light: {
      tight: "var(--tightShadowLight)",
      button: "var(--buttonShadowLight)",
      buttonTight: "var(--buttonTightShadowNeutral)",
      image: "var(--imageShadowLight)",
      blur: "var(--blurShadowLight)",
      outerBlur: "var(--outerBlurShadowLight)",
      inset: "var(--insetShadowLight)",
    },
    dark: {
      tight: "var(--tightShadowDark)",
      button: "var(--buttonShadowDark)",
      buttonTight: "var(--buttonTightShadowNeutral)",
      image: "var(--imageShadowDark)",
      blur: "var(--blurShadowDark)",
      outerBlur: "var(--outerBlurShadowDark)",
      inset: "var(--insetShadowDark)",
    },

    definitions: {
      // Light Mode
      "--tightShadowLight":
        "0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 2px 2px #FFF",

      "--buttonShadowLight": [
        "inset 0px -1px 2px #FFFFFF, inset 0px 3px 4px rgba(0, 0, 0, 0.15)",
        "inset 0px -0.5px 2px #FFFFFF, inset 0px 2px 3px rgba(0, 0, 0, 0.15)",
      ],

      "--imageShadowLight": [
        "0px 2px 3px rgba(0, 0, 0, 0.14), 0px 1px 2px rgba(0, 0, 0, 0.16), inset 0px 1.5px 2px #FFFFFF",
        "0px 2px 3px rgba(0, 0, 0, 0.14), 0px 1px 2px rgba(0, 0, 0, 0.16), inset 0px 1.5px 2px rgba(255, 255, 255, 0.5)",
      ],

      "--blurShadowLight": [
        "0px 40px 54px rgba(0, 0, 0, 0.14), inset 0px -1px 1px rgba(0, 0, 0, 0.1), inset 0px 1px 2px #FFFFFF",
        "0px 18px 36px rgba(0, 0, 0, 0.14), inset 0px -1px 1px rgba(0, 0, 0, 0.1), inset 0px 1px 2px #FFFFFF",
      ],

      "--outerBlurShadowLight": [
        "0px 40px 54px rgba(0, 0, 0, 0.14)",
        "0px 18px 36px rgba(0, 0, 0, 0.14)",
      ],

      "--buttonTightShadowNeutral": [
        "0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.5)",
        "0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(255, 255, 255, 0.5)",
      ],

      "--insetShadowLight": [
        "0px -0.75px 0.75px rgba(0, 0, 0, 0.25), 0px 1px 2px #FFFFFF, inset 0px 2px 5px rgba(0, 0, 0, 0.2)",
        "0px -0.75px 0.75px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(255, 255, 255, 0.18), inset 0px 2px 5px rgba(0, 0, 0, 0.2)",
      ],

      // Dark Mode
      "--buttonShadowDark": [
        "inset 0px -1px 2px rgba(255, 255, 255, 0.4), inset 0px 3px 4px rgba(0, 0, 0, 0.15)",
        "inset 0px -1px 2px rgba(255, 255, 255, 0.3), inset 0px 2px 3px rgba(0, 0, 0, 0.15)",
      ],

      "--blurShadowDark": [
        "0px 40px 54px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(0, 0, 0, 0.18), inset 0px 1px 2px rgba(255, 255, 255, 0.15)",
        "0px 18px 36px rgba(0, 0, 0, 0.3), 0px 1px 2px rgba(0, 0, 0, 0.17), inset 0px 1px 2px rgba(255, 255, 255, 0.15)",
      ],

      "--outerBlurShadowDark": [
        "0px 40px 54px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(0, 0, 0, 0.18)",
        "0px 18px 36px rgba(0, 0, 0, 0.3), 0px 1px 2px rgba(0, 0, 0, 0.17)",
      ],

      "--tightShadowDark": [
        "0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.15)",
      ],

      "--insetShadowDark": [
        "0px -0.75px 0.75px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(255, 255, 255, 0.18), inset 0px 2px 5px rgba(0, 0, 0, 0.2)",
        "0px -0.75px 0.75px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(255, 255, 255, 0.2), inset 0px 1px 4px rgba(0, 0, 0, 0.15)",
      ],

      "--imageShadowDark": [
        "0px 2px 3px rgba(0, 0, 0, 0.14), 0px 1px 2px rgba(0, 0, 0, 0.16), inset 0px 1.5px 2px rgba(255, 255, 255, 0.3)",
        "0px 2px 3px rgba(0, 0, 0, 0.14), 0px 1px 2px rgba(0, 0, 0, 0.16), inset 0px 1px 2px rgba(255, 255, 255, 0.3)",
      ],
    },
  },
  ["root", mediaQueries.mobile]
)

const compileStyles = (set, type) =>
  Object.entries(set).reduce(
    (output, [name, value]) => {
      output.vars[`--${name}${type}`] = value
      output.pointers[name] = `var(--${name}${type})`
      return output
    },
    { vars: {}, pointers: {} }
  )

const tokens = {
  colors: {
    light: compileStyles(colors.light, "Color"),
    dark: compileStyles(colors.dark, "Color"),
  },
  shadows: {
    light: compileStyles(shadows.light, "Shadow"),
    dark: compileStyles(shadows.dark, "Shadow"),
  },
}

export default {
  ...textStyles,
  mq: mediaQueries,
  shadows: {
    ...tokens.shadows.light.pointers,
    ...tokens.shadows.dark.pointers,

    // Warning: avoid using this; it was not fully baked in the design
    // and only works for light-mode styles; it does not have a mobile
    // version or use our color tokens.
    shallow:
      "0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 2px 2px #FFFFFF",
  },
  colors: {
    ...colors.palette,
    ...tokens.colors.light.pointers,
    ...tokens.colors.dark.pointers,
  },
  modes: {
    light: {
      ...tokens.colors.light.vars,
      ...tokens.shadows.light.vars,
    },
    dark: {
      ...tokens.colors.dark.vars,
      ...tokens.shadows.dark.vars,
    },
  },
  hideOutlines: {
    [["a", "button", "input", "video"]]: {
      outline: "none",
    },
  },
  expandQueries: (styles) => repackage(styles, ["root", mediaQueries.mobile]),
  expandFull: (styles) =>
    repackage(styles, ["root", mediaQueries.tablet, mediaQueries.mobile]),
}

export const globalStyles = [
  normalize,
  fontDeclarations,
  {
    ":root": merge({}, customProperties, shadowDefinitions),

    [["*", "*:before", "*:after"]]: {
      boxSizing: "inherit",
    },

    html: {
      ...textStyles.p2,
      wordBreak: "break-word",
      boxSizing: "border-box",

      // Improve browser font rendering
      textRendering: "optimizeLegibility",
      fontFeatureSettings: "'liga', 'kern' 1",
      fontKerning: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },

    img: {
      display: "block",
      maxWidth: "100%",
    },

    svg: {
      display: "block",
      maxWidth: "100%",
    },

    video: {
      display: "block",
    },

    [["h1", "h2", "h3", "h4", "h5", "h6"]]: {
      margin: 0,
    },

    pre: {
      maxWidth: "100%",
      overflowX: "auto",
    },

    strong: {
      fontWeight: 500,
    },
  },
]
