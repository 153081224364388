import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import { globalStyles } from "styles/theme"
import Favicons from "components/Favicons"
import NetlifyFormPlaceholder from "components/NetlifyFormPlaceholder"

const PageWrapper = ({ element }) => (
  <>
    <Global styles={globalStyles} />
    <Favicons />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Kosugi&amp;family=Kosugi+Maru&amp;display=swap"
      rel="stylesheet"
    />
    {element}

    {/* Register forms with Netlify */}
    <NetlifyFormPlaceholder name="newsletter-signup" fields={["email"]} />
    <NetlifyFormPlaceholder
      name="contact-form"
      fields={["name", "email", "message"]}
    />
  </>
)

export default PageWrapper

PageWrapper.propTypes = {
  element: PropTypes.any.isRequired,
}
