import Algebra400 from "assets/fonts/AlgebraDisplay-Regular-Web.woff2"
import Algebra500 from "assets/fonts/AlgebraDisplay-RegularNo2-Web.woff2"
import DJRText400 from "assets/fonts/FormaDJRText-Regular.woff2"
import DJRText500 from "assets/fonts/FormaDJRText-Medium.woff2"

export default [
  {
    "@font-face": {
      fontFamily: "SMAlgebraDisplay",
      fontStyle: "normal",
      fontWeight: 400,
      src: `url(${Algebra400}) format("woff2")`,
    },
  },
  {
    "@font-face": {
      fontFamily: "SMAlgebraDisplay",
      fontStyle: "normal",
      fontWeight: 500,
      src: `url(${Algebra500}) format("woff2")`,
    },
  },
  {
    "@font-face": {
      fontFamily: "SMForma",
      fontStyle: "normal",
      fontWeight: 400,
      src: `url(${DJRText400}) format("woff2")`,
    },
  },
  {
    "@font-face": {
      fontFamily: "SMForma",
      fontStyle: "normal",
      fontWeight: 500,
      src: `url(${DJRText500}) format("woff2")`,
    },
  },
]
